import { Link } from 'react-router-dom';
import SRC_ARROW from "../assets/img/arrow.svg";
import { ButtonBack } from '../components/ButtonBack';
import SRC_SURFYX from "../assets/img/case-studies/surfyx.jpg";
import SRC_SURFYXWO from "../assets/img/case-studies/surfyx-2.jpg";
import SRC_SURFYXTHREE from "../assets/img/case-studies/surfyx-3.jpg";
import SRC_SURFYXFOUR from "../assets/img/case-studies/surfyx-4.jpg";
import SRC_SURFYXFIVE from "../assets/img/case-studies/surfyx-5.jpg";
import { motion } from 'framer-motion';
import ImageSlider from '../components/ImageSlider';

export function Surfyx() {
    const images = [
        SRC_SURFYX,
        SRC_SURFYXWO,
        SRC_SURFYXTHREE,
        SRC_SURFYXFOUR,
        SRC_SURFYXFIVE

    ];
    return (

        <motion.div initial={{opacity:0, scale:0.8}} transition={{duration:0.3}} animate={{opacity:1, scale:1}} exit={{opacity:0, scale:0.8}} className="w-full h-full md:flex justify-between items-center bg-gray-50 text-neutral-800 md:overflow-hidden overflow-auto fixed z-[99999]">
            <ButtonBack />

            <div className="w-full overflow-hidden h-1/2 md:h-full md:w-1/2 order-2 bg-neutral-950">
                {/*<motion.img initial={{scale:1}} animate={{scale:1.05}} transition={{duration:4}} src={SRC_BABONBO} className='w-full h-full object-cover' />*/}
                <ImageSlider images={images} />
            </div>
            <div className="w-full md:w-1/2 md:h-full order-1 overflow-auto md:p-28 md:pt-36 p-6 md:my-auto">
                <div>
                    <div className="text-sm text-teal-400 uppercase">Case study</div>
                    <h1 className="text-3xl md:text-6xl font-bold">Surfyx</h1>
                    <div className="text-xl mt-4">An app designed to elevate the surfing experience by providing surfers with detailed statistics about their sessions.</div>
                    <div className="flex gap-4 justify-between text-base py-7 border-b border-t border-neutral-200 my-10">
                        <div className="">
                            <div className="font-bold">Year</div>
                            <div>2024</div>
                        </div>

                        <div className="">
                            <div className="font-bold">Solution</div>
                            <div>Mobile App</div>
                        </div>

                        <div className="">
                            <div className="font-bold">Website</div>
                            <div>Coming soon</div>
                        </div>
                    </div>
                    <div>
                        With Surfyx, surfers can track various metrics such as wave count, ride length, and performance analytics, giving them valuable insights into their progress and areas for improvement. After each session, surfers can post their results on the app, allowing their followers to see their achievements and interact with their content. This feature fosters a vibrant community where surfers can connect, share their passion, and motivate each other to reach new heights.

                        <br /><br />
                        The social aspect of Surfyx is enhanced by its unique feature that allows users to follow their favorite surfers and view detailed ratings and statistics for each surf spot. Surfers can filter posts by location, enabling them to see the latest sessions at their preferred spots and discover new locations with favorable conditions. This functionality helps surfers stay informed about the best spots to catch waves and provides a platform for sharing experiences and tips with the community. By keeping track of surf spot ratings and session statistics, Surfyx users can make more informed decisions about where and when to surf.
                        <br /><br />
                        In addition to its social and statistical features, Surfyx also offers a comprehensive marketplace for all things surfing. Users can find nearby surf instructors, rent boards, book surf trips, and explore various other services and products. This marketplace connects surfers with essential resources, making it easier than ever to access high-quality instruction, equipment, and adventure opportunities. Whether you are a beginner looking for lessons or an experienced surfer planning your next trip, Surfyx provides the tools and community support to enhance every aspect of your surfing journey.

                    </div>
                </div>
            </div>

        </motion.div>
    );
} 