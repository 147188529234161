// ImageSlider.js
import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

import SRC_ARROW from "../assets/img/chevron.svg";

const ImageSlider = ({ images }) => {
  const [current, setCurrent] = useState(0);

  const nextSlide = () => {
    setCurrent((prev) => (prev === images.length - 1 ? 0 : prev + 1));
  };

  const prevSlide = () => {
    setCurrent((prev) => (prev === 0 ? images.length - 1 : prev - 1));
  };

  return (
    <div className="relative w-full h-full mx-auto overflow-hidden">
      <div className="relative w-full h-full">
        <AnimatePresence initial={false}>
          <motion.div
            key={images[current]}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, scale: 1.05 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
            className="absolute inset-0 w-full h-full"
          >
            <img src={images[current]} alt="" className="w-full h-full object-cover" />
          </motion.div>
        </AnimatePresence>
      </div>
      <button
        onClick={prevSlide}
        className="absolute bottom-0 md:bottom-auto md:top-1/2 left-0 transform md:-translate-y-1/2 bg-neutral-800 size-11 md:size-14 flex items-center justify-center"
      >
        <img src={SRC_ARROW} />
      </button>
      <button
        onClick={nextSlide}
        className="absolute bottom-0 md:bottom-auto md:top-1/2 right-0 transform md:-translate-y-1/2 bg-neutral-800 size-11 md:size-14 flex items-center justify-center"
      >
        <img className="rotate-180" src={SRC_ARROW} />
      </button>
    </div>
  );
};

export default ImageSlider;
