
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';

import { Home } from './pages/Home'
import { Proposal } from './pages/Proposal'
import { Babonbo } from './pages/Babonbo'
import { Avioyx } from './pages/Avioyx';
import { Surfyx } from './pages/Surfyx';
import { Clickai } from './pages/Clickai';
import { Dogu } from './pages/Dogu';
import { Maya } from './pages/Maya';
import { CaseStudies } from './pages/CaseStudies';

const App = () => {
  const location = useLocation();
  return (

      <AnimatePresence>
        <Routes location={location} key={location.pathname}>
          <Route path="/" element={<Home />} />
          <Route path="/proposals/ecuestrian" element={<Proposal />} />
          <Route path="/case-studies" element={<CaseStudies />} />
          <Route path="/case-study/babonbo" element={<Babonbo />} />
          <Route path="/case-study/avioyx" element={<Avioyx />} />
          <Route path="/case-study/surfyx" element={<Surfyx />} />
          <Route path="/case-study/clickai" element={<Clickai />} />
          <Route path="/case-study/dogu" element={<Dogu />} />
          <Route path="/case-study/maya" element={<Maya />} />
        </Routes>
      </AnimatePresence>

  );
}

const WrappedApp = () => (
  <Router>
    <App />
  </Router>
);


export default WrappedApp;
