import { Link } from 'react-router-dom';
import { ButtonBack } from '../components/ButtonBack';
import SRC_BABONBO from "../assets/img/case-studies/babonbo.jpg";
import SRC_BABONBOTWO from "../assets/img/case-studies/babonbo-2.jpg";
import SRC_BABONBOTHREE from "../assets/img/case-studies/babonbo-3.jpg";
import SRC_BABONBOFOUR from "../assets/img/case-studies/babonbo-4.jpg";
import { motion } from 'framer-motion';
import ImageSlider from '../components/ImageSlider';

export function Babonbo() {
    const images = [
        SRC_BABONBO,
        SRC_BABONBOTWO,
        SRC_BABONBOTHREE,
        SRC_BABONBOFOUR,
        
      ];
    return (

        <motion.div initial={{opacity:0, scale:0.8}} transition={{duration:0.3}} animate={{opacity:1, scale:1}} exit={{opacity:0, scale:0.8}} className="w-full h-full md:flex justify-between items-center bg-gray-50 text-neutral-800 md:overflow-hidden overflow-auto fixed z-[99999]">
            <ButtonBack />
            
                <div className="w-full overflow-hidden h-1/2 md:h-full md:w-1/2 order-2 bg-neutral-950">
                    {/*<motion.img initial={{scale:1}} animate={{scale:1.05}} transition={{duration:4}} src={SRC_BABONBO} className='w-full h-full object-cover' />*/}
                    <ImageSlider images={images} />
                </div>
                <motion.div className="w-full md:w-1/2 md:h-full order-1 overflow-auto md:p-28 md:pt-36 p-6 md:my-auto">
                    <div>
                        <div className="text-sm text-teal-400 uppercase">Case study</div>
                        <h1 className="text-3xl md:text-6xl font-bold">Babonbo</h1>
                        <div className="text-xl mt-4">Babonbo is the first mobile app that connects families with local parents to rent high-quality baby equipment for a sustainable travel experience!</div>
                        <div className="flex gap-4 justify-between text-base py-7 border-b border-t border-neutral-200 my-10">
                            <div className="">
                                <div className="font-bold">Year</div>
                                <div>2023</div>
                            </div>

                            <div className="">
                                <div className="font-bold">Solution</div>
                                <div>Web & Mobile App</div>
                            </div>
                            
                            <div className="">
                                <div className="font-bold">Website</div>
                                <Link className="text-teal-400" target='_blank' to="https://www.babonbo.com/">babonbo.com</Link>
                            </div>
                        </div>
                        <div>
                        Whether you're at home or on the go, Babonbo offers a convenient and affordable solution to your baby gear needs. Babonbo is committed to building a sustainable and smart community of parents. 
                        <br /><br />
                        The app promotes responsible consumption and waste reduction, while also providing families with access to high-quality baby equipment at affordable prices.
                        </div>
                    </div>
                </motion.div>
            </motion.div>
        
    );
} 