import { Navbar } from "../components/Navbar";

import { FlipWords } from "../components/Words";
import { Link } from 'react-router-dom';
import { AuroraBackground } from "../components/Aurora";

import SRC_BABONBO from "../assets/img/case-studies/babonbo-2.jpg";
import SRC_CLICKAI from "../assets/img/case-studies/clickai-2.jpg";
import SRC_DOGU from "../assets/img/case-studies/dogu-2.jpg";
import SRC_AVIOYX from "../assets/img/case-studies/avioyx-2.jpg";
import SRC_SURFYX from "../assets/img/case-studies/surfyx-2.jpg";
import SRC_MAYA from "../assets/img/case-studies/maya-2.jpg";




import { motion, useScroll, Variants, useTransform } from "framer-motion";


export function CaseStudies() {
    const cardVariants = {
        offscreen: {
            y: 300
        },
        onscreen: {
            y: 50,
            rotate: -10,
            transition: {
                type: "spring",
                bounce: 0.4,
                duration: 0.8
            }
        }
    };



    const { scrollYProgress } = useScroll();
    return (
        <>
            <motion.div className='h-1 w-full origin-left bg-teal-400 fixed top-0 left-0 z-50' style={{ scaleX: scrollYProgress }} />
            <Navbar />
            <div className="w-full md:p-24 p-6 grid grid-cols-3 gap-12 justify-center">
                <StudyItem url="/case-study/babonbo" title="Babonbo" description="Web & mobile app" img={SRC_BABONBO} />
                <StudyItem url="/case-study/clickai" title="ClickAi" description="Web app"  img={SRC_CLICKAI} />
                <StudyItem url="/case-study/dogu" title="Dogu" description="Web & mobile app" img={SRC_DOGU}  />
                <StudyItem url="/case-study/avioyx" title="Avioyx" description="iPad App" img={SRC_AVIOYX}  />
                <StudyItem url="/case-study/surfyx" title="Surfyx" description="Mobile app" img={SRC_SURFYX} />
                <StudyItem url="/case-study/maya" title="Maya" description="Web app" img={SRC_MAYA} />
            </div>
            <Footer />



        </>
    );
}



const StudyItem = (props) => {
    const { title, description, url, img } = props;
    return (
        <Link to={url} className="text-white flex flex-col p-2 pb-0  rounded-3xl" >
            <img src={img} className="w-full rounded-3xl" />
            <div className="p-6 rounded-3xl bg-neutral-900">
                <div className="text-xl font-bold">{title}</div>
                <div className="text-sm text-neutral-600">{description}</div>
            </div>
        </Link>
    );
};




const Footer = () => {

    return (
        <div className="w-full bg-neutral-900 relative">
            <div className="max-w-screen-xl mx-auto py-16 bg-neutral-900 text-white text-center">
                <div className="w-full text-sm">&copy; AllZem 2024</div>
            </div>
        </div>
    );
};