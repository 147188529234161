import { motion, useScroll, Variants } from "framer-motion";
import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';

import SRC_ARROW from "../assets/img/arrow-down.svg";
import SRC_CLOSE from "../assets/img/close.svg";


export function CaseSide({ isOpen, toggleSidebar }) {
    const sidebarVariants = {
        open: { x: 0, transition: { stiffness: 1000 } },
        closed: { x: '100%', transition: { stiffness: 1000 } }
    };

    return (
        <>


            <motion.div
                initial={false}
                animate={isOpen ? 'open' : 'closed'}
                variants={sidebarVariants}

                className="h-full bg-neutral-900 overflow-auto right-0 top-0 fixed md:w-1/2 w-full z-[99999] md:p-16 p-6">
                <div className="text-4xl font-bold mb-8 text-white flex items-center justify-between">Case studies

                    <img src={SRC_CLOSE} onClick={toggleSidebar} className="cursor-pointer" />
                </div>
                <StudyItem url="/case-study/babonbo" title="Babonbo" description="Web & mobile app" />
                <StudyItem url="/case-study/clickai" title="ClickAi" description="Web app" />
                <StudyItem url="/case-study/dogu" title="Dogu" description="Web & mobile app" />
                <StudyItem url="/case-study/avioyx" title="Avioyx" description="iPad App" />
                <StudyItem url="/case-study/surfyx" title="Surfyx" description="Mobile app" />
                <StudyItem url="/case-study/maya" title="Maya" description="Web app" />
            </motion.div>
        </>
    );
};

const StudyItem = (props) => {
    const { title, description, url } = props;
    return (
        <Link to={url} className="text-white group hover:text-teal-400 flex items-center justify-between py-6 border-b border-neutral-800 last-of-type:border-none" >
            <div>
                <div className="text-xl font-bold">{title}</div>
                <div className="text-sm text-neutral-500 group-hover:text-white">{description}</div>
            </div>
            <img src={SRC_ARROW} className="-rotate-90 transition-all duration-200 translate-x-2 group-hover:translate-x-0 opacity-0 group-hover:opacity-100" />
        </Link>
    );
};