import { Link } from 'react-router-dom';
import SRC_ARROW from "../assets/img/arrow.svg";
import { ButtonBack } from '../components/ButtonBack';
import SRC_DOGU from "../assets/img/case-studies/dogu.jpg";
import SRC_DOGUWO from "../assets/img/case-studies/dogu-2.jpg";
import SRC_DOGUTHREE from "../assets/img/case-studies/dogu-3.jpg";
import SRC_DOGUFOUR from "../assets/img/case-studies/dogu-4.jpg";

import { motion } from 'framer-motion';
import ImageSlider from '../components/ImageSlider';

export function Dogu() {
    const images = [
        SRC_DOGU,
        SRC_DOGUWO,
        SRC_DOGUTHREE,
        SRC_DOGUFOUR

    ];
    return (

        <motion.div initial={{opacity:0, scale:0.8}} transition={{duration:0.3}} animate={{opacity:1, scale:1}} exit={{opacity:0, scale:0.8}} className="w-full h-full md:flex justify-between items-center bg-gray-50 text-neutral-800 md:overflow-hidden overflow-auto fixed z-[99999]">
            <ButtonBack />
            
                <div className="w-full overflow-hidden h-1/2 md:h-full md:w-1/2 order-2 bg-neutral-950">
                    {/*<motion.img initial={{scale:1}} animate={{scale:1.05}} transition={{duration:4}} src={SRC_BABONBO} className='w-full h-full object-cover' />*/}
                    <ImageSlider images={images} />
                </div>
                <div className="w-full md:w-1/2 md:h-full order-1 overflow-auto md:p-28 md:pt-36 p-6 md:my-auto">
                    <div>
                        <div className="text-sm text-teal-400 uppercase">Case study</div>
                        <h1 className="text-3xl md:text-6xl font-bold">Dogu</h1>
                        <div className="text-xl mt-4">Find the perfect match for your dog: trainers, walkers, groomers, veterinarians.</div>
                        <div className="flex gap-4 justify-between text-base py-7 border-b border-t border-neutral-200 my-10">
                            <div className="">
                                <div className="font-bold">Year</div>
                                <div>2024</div>
                            </div>

                            <div className="">
                                <div className="font-bold">Solution</div>
                                <div>Web & Mobile App</div>
                            </div>

                            <div className="">
                                <div className="font-bold">Website</div>
                                <Link className='text-teal-400' to="https://www.dogu.app/" target='_blank'>dogu.app</Link>
                            </div>
                        </div>
                        <div>
                            Interactive social media platform designed exclusively for dogs and their owners! With Dogu, immerse yourself in a vibrant community where the love for dogs unites everyone. Our user-friendly app is tailored to enhance the well-being and happiness of your four-legged friends, offering a suite of features that cater to every aspect of dog care and socialization.



                            <br /><br />
                            Pawfect Match: Find playmates for your dog based on location, breed, and interests. Socialize your dog with nearby friends for walks and playdates.
                            <br /><br />

                            Expert Dog Walkers: Our app connects you with trusted, experienced dog walkers in your neighborhood. Ensure your dog gets its needed exercise and social time, even on your busiest days.
                            <br /><br />

                            Training Made Easy: Match with professional dog trainers. From basic obedience to advanced tricks, help your dog learn and grow.

                            <br /><br />
                            Exciting Challenges: Participate in fun dog challenges. Earn rewards, recognition, and bragging rights in our supportive community.
                            <br /><br />

                            Grooming Galore: Discover top-rated groomers to keep your dog looking sharp. Book appointments easily through the app.
                            <br /><br />

                            Veterinary Access: Find and connect with highly-rated vets for regular checkups and emergency care, ensuring your dog's health and well-being.
                            <br /><br />

                            Adoption & Rescue: Extend your family by adopting dogs from local shelters. Our app makes finding a new companion simple and heartwarming.
                            <br /><br />

                            Marketplace: Explore our extensive marketplace for high-quality dog food, toys, and accessories. Everything your dog needs, all in one place.
                            <br /><br />

                            Breeding Connections: Safely find suitable breeding partners for your dog, supported by our comprehensive dog profile system.

                            <br /><br />
                            Dog Stay Services: Travel worry-free! Find reliable dog sitters in your area through Dogu.

                        </div>
                    </div>
                </div>

        </motion.div>
    );
} 