import { Link } from 'react-router-dom';
import SRC_ARROW from "../assets/img/arrow.svg";
import { ButtonBack } from '../components/ButtonBack';
import SRC_MAYA from "../assets/img/case-studies/maya.jpg";
import SRC_MAYAWO from "../assets/img/case-studies/maya-2.jpg";
import SRC_MAYATHREE from "../assets/img/case-studies/maya-3.jpg";
import SRC_MAYAFOUR from "../assets/img/case-studies/maya-4.jpg";
import SRC_MAYAFIVE from "../assets/img/case-studies/maya-5.jpg";

import { motion } from 'framer-motion';
import ImageSlider from '../components/ImageSlider';

export function Maya() {
    const images = [
        SRC_MAYA,
        SRC_MAYAWO,
        SRC_MAYATHREE,
        SRC_MAYAFOUR,
        SRC_MAYAFIVE

    ];
    return (

        <motion.div initial={{opacity:0, scale:0.8}} transition={{duration:0.3}} animate={{opacity:1, scale:1}} exit={{opacity:0, scale:0.8}} className="w-full h-full md:flex justify-between items-center bg-gray-50 text-neutral-800 md:overflow-hidden overflow-auto fixed z-[99999]">
            <ButtonBack />
            
                <div className="w-full overflow-hidden h-1/2 md:h-full md:w-1/2 order-2 bg-neutral-950">
                    {/*<motion.img initial={{scale:1}} animate={{scale:1.05}} transition={{duration:4}} src={SRC_BABONBO} className='w-full h-full object-cover' />*/}
                    <ImageSlider images={images} />
                </div>
                <div className="w-full md:w-1/2 md:h-full order-1 overflow-auto md:p-28 md:pt-36 p-6 md:my-auto">
                    <div>
                        <div className="text-sm text-teal-400 uppercase">Case study</div>
                        <h1 className="text-3xl md:text-6xl font-bold">Maya</h1>
                        <div className="text-xl mt-4">Maya is an advanced AI voice assistant that revolutionizes the process of building and managing websites, e-commerce platforms, blogs, and business finances with its standout feature: the bot builder.</div>
                        <div className="flex gap-4 justify-between text-base py-7 border-b border-t border-neutral-200 my-10">
                            <div className="">
                                <div className="font-bold">Year</div>
                                <div>2024</div>
                            </div>

                            <div className="">
                                <div className="font-bold">Solution</div>
                                <div>Web App</div>
                            </div>

                            <div className="">
                                <div className="font-bold">Website</div>
                                <div>Coming soon</div>
                            </div>
                        </div>
                        <div>
                        This bot builder allows users to create custom chatbots and automation sequences tailored to their specific needs, enhancing user engagement and operational efficiency. By integrating seamlessly into various web development and business management tools, Maya offers real-time assistance, answering queries, providing step-by-step guidance, and automating complex tasks, making it an indispensable tool for users at all skill levels.




                            <br /><br />
                            Beyond web development, Maya excels in supporting e-commerce businesses by assisting with inventory management, customer service, and sales tracking. The AI can help set up online stores, optimize product listings, and manage orders, ensuring a smooth and efficient operation. For bloggers, Maya offers content management support, including SEO optimization, scheduling posts, and monitoring site traffic. With its comprehensive understanding of digital marketing strategies, Maya helps users maximize their online presence and reach their target audience effectively.

                            <br /><br />

                            Maya's capabilities extend to business finance management, offering tools to track expenses, generate reports, and manage budgets effortlessly. By providing personalized financial insights and recommendations, Maya empowers business owners to make informed decisions and maintain financial health. With its versatile and user-friendly interface, and especially its powerful bot builder feature, Maya is the ultimate AI assistant for anyone looking to enhance their website development, e-commerce operations, blog management, and business finance tasks, making it an essential tool in the digital age.
                            
                        </div>
                    </div>
                </div>

        </motion.div>
    );
} 