import SRC_PRINT from '../assets/img/print.svg';
import SRC_MONEY from '../assets/img/dollar.svg';
import SRC_TIME from '../assets/img/time.svg';
import SRC_LOGO from '../assets/img/logo.jpg';
import SRC_SIGN from '../assets/img/signature.png';
import SRC_HORSE from '../assets/img/horse.jpg';
import SRC_ARROW from '../assets/img/arrow-down.svg';



import { motion, useScroll, Variants } from "framer-motion";
import React, { useState, useEffect } from "react";


export function Proposal() {
    const cardVariants = {
        offscreen: {
            y: 300
        },
        onscreen: {
            y: 50,
            rotate: -10,
            transition: {
                type: "spring",
                bounce: 0.4,
                duration: 0.8
            }
        }
    };


    const { scrollYProgress } = useScroll();
    return (
        <>

            <Navbar />
            <motion.div className='h-1 w-full origin-left bg-teal-400 fixed top-0 left-0 z-50' style={{ scaleX: scrollYProgress }} />

            <div className='w-screen h-full relative bg-gradient-to-b from-stone-900 to-stone-800 print:bg-white text-white print:text-black p-10 md:p-24  print:p-0 flex flex-col items-center justify-center md:items-start md:justify-end print:justify-center'>
                <img src={SRC_LOGO} className='print:hidden mix-blend-multiply absolute w-[350px] bottom-0 right-0 opacity-30' alt='' />


                <div className='md:w-full relative z-30 md:text-left'>
                    <div className='text-4xl md:text-7xl print:text-7xl font-bold mb-4'>Web & Mobile App <br /> <span className="text-teal-400 bg-gradient-to-r from-teal-600 text-transparent to-teal-200 bg-clip-text">Development Proposal</span>.</div>
                    <div className='text-sm md:text-lg font-light'>for <span className="text-teal-400">Ecuestrian</span> on 13 Jun, 2024 by Ahmed Allem</div>
                </div>
                <div className='w-full p-6 flex justify-center md:justify-end absolute bottom-0 left-0'>
                    <img className='animate-bounce' src={SRC_ARROW} alt='' />
                </div>

            </div>
            <div className='w-full min-h-screen flex items-center justify-center bg-gray-50'>
                <motion.div
                    initial={{ y: 200 }}
                    whileInView={{ y: 0 }}
                    transition={{ damping: 1, string: 2 }}
                    viewport={{ once: true }}
                    className='max-w-screen-md mx-auto py-32 p-6'>
                    <div className='w-full'>
                        <div className='text-4xl md:text-6xl font-bold text-teal-400'>Dear Ecuestrian,</div>
                        <div className='text-lg font-light my-6'>
                            Our proposal outlines a comprehensive plan for the development of a seamless and high-performing web application, alongside native iOS and Android mobile applications. <br /><br />By leveraging cutting-edge technologies and best practices, our goal is to deliver a unified user experience across all platforms. <br /><br />This approach ensures consistency, reliability, and an engaging interface for users, tailored to each specific platform's strengths. Our team is dedicated to creating scalable, secure, and user-friendly applications that meet your business objectives and exceed user expectations.
                        </div>
                        <div className='text-lg font-bold'>Let's build something great!</div>
                        <img src={SRC_SIGN} className='w-40 mt-6' alt='' />
                        <div className='text-2xl font-bold text-teal-400'>Ahmed Allem</div>
                        <div className='text-lg'>Lead developer</div>
                    </div>
                </motion.div>
            </div>

            <div className='w-full min-h-screen flex items-start justify-between bg-gradient-to-b from-stone-900 to-stone-800'>
                <motion.div
                    initial={{ y: 200 }}
                    whileInView={{ y: 0 }}
                    transition={{ damping: 1, string: 2 }}
                    viewport={{ once: true }}
                    className='max-w-screen-lg mx-auto py-32 p-6'>
                    <div className='w-full flex md:flex-nowrap flex-wrap'>
                        <div className='text-4xl md:text-6xl md:w-1/2 font-bold bg-gradient-to-r from-teal-600 text-transparent to-teal-200 bg-clip-text mb-12'>
                            <div>Application Components</div>
                            <img className='w-3/5 mt-12 rounded-3xl border-8 shadow-2xl border-black print:hidden md:block hidden' src={SRC_HORSE} alt='' />
                        </div>
                        <div className='md:w-1/2 flex-shrink text-white print:text-black'>
                            <div className='w-full mb-10'>
                                <div className='text-2xl font-bold'>UX/UI Design</div>
                                <div className='w-1/3 h-1 bg-teal-400 my-3 print:hidden' />
                                <div className='text-base'>We specialize in creating intuitive and visually appealing interfaces that enhance user engagement and ensure seamless experiences across all devices.</div>
                            </div>
                            <div className='w-full mb-10'>
                                <div className='text-2xl font-bold'>Web Development</div>
                                <div className='w-1/3 h-1 bg-teal-400 my-3 print:hidden' />
                                <div className='text-base'>Our team develops dynamic and responsive web applications that deliver high performance and robust user interfaces.</div>
                            </div>
                            <div className='w-full mb-10'>
                                <div className='text-2xl font-bold'>Mobile Development</div>
                                <div className='w-1/3 h-1 bg-teal-400 my-3 print:hidden' />
                                <div className='text-base'>We build cross-platform mobile applications for iOS and Android, ensuring consistent functionality and appearance on both platforms.</div>
                            </div>
                            <div className='w-full mb-10'>
                                <div className='text-2xl font-bold'>Financial Integration</div>
                                <div className='w-1/3 h-1 bg-teal-400 my-3 print:hidden' />
                                <div className='text-base'>Our services include secure financial integration, enabling seamless and secure transactions within your application, supporting various payment methods while ensuring compliance with financial regulations.</div>
                            </div>
                        </div>
                    </div>
                </motion.div>
            </div>

            <AppList />

            <div className='w-full min-h-[50px] flex items-start justify-between bg-gradient-to-tr from-teal-400 to-teal-600'>
                <div className='max-w-screen-lg mx-auto py-10 p-6'>
                </div>
            </div>

            <div className='w-full min-h-screen flex items-start justify-start bg-gray-50 relative'>
                <img src={SRC_LOGO} className='print:hidden absolute bottom-0 right-0 opacity-10 md:w-[400px] w-[200px]' alt='' />
                <div className='max-w-screen-md w-full mx-auto py-32 print:py-6 p-6 relative z-20'>
                    {/*<div className='text-5xl font-bold mb-12'>Timeline & <span className='text-teal-400'>Costs</span></div>*/}
                    <motion.div
                        initial={{ y: 200 }}
                        whileInView={{ y: 0 }}
                        transition={{ damping: 1, string: 2 }}
                        viewport={{ once: true }} className='md:flex w-full p-6 md:p-12 print:p-4 bg-white rounded-3xl'>
                        <div className='md:w-1/2 relative mb-4'>
                            <div className='size-3 outline outline-1 outline-offset-2 outline-teal-400 rounded-full absolute left-0 top-2 bg-teal-400 md:hidden hidden' />
                            <div className='text-base text-teal-400'>Phase one</div>
                            <div className='text-4xl font-bold'>Design</div>
                        </div>
                        <div className='md:w-1/2 '>
                            <div className='flex items-center my-2'>
                                User interface and user experience design
                            </div>
                            <div className='flex items-center my-2 '>
                                Web app design
                            </div>
                            <div className='flex items-center my-2 '>
                                Mobile app design
                            </div>
                            <div className='flex items-center my-2 '>
                                App prototype in Figma
                            </div>
                            <div className='flex items-center my-2 '>
                                Approval of design mockups
                            </div>
                            <div className='w-1/4 h-1 bg-teal-400 my-6 print:hidden' />
                            <div className='flex items-center print:mt-2 mb-2 font-bold'>
                                <img src={SRC_TIME} className='size-5 mr-2' alt='' />
                                1.5 Months
                            </div>
                            <div className='flex items-center my-2 font-bold'>
                                <img src={SRC_MONEY} className='size-5 mr-2' alt='' />
                                $25,000.00 USD
                            </div>

                        </div>
                    </motion.div>

                    <motion.div
                        initial={{ y: 200 }}
                        whileInView={{ y: 0 }}
                        transition={{ damping: 1, string: 2 }}
                        viewport={{ once: true }} className='md:flex w-full p-6 md:p-12 print:p-4 bg-white rounded-3xl my-6 md:my-12  print:my-4'>
                        <div className='md:w-1/2 relative mb-4'>
                            <div className='size-3 outline outline-1 outline-offset-2 outline-teal-400 rounded-full absolute left-0 top-2 bg-teal-400 md:hidden hidden' />
                            <div className='text-base text-teal-400'>Phase two</div>
                            <div className='text-4xl font-bold'>Development </div>
                        </div>

                        <div className='md:w-1/2 '>
                            <div className='flex items-center my-2'>
                                Backend development: $90,000
                            </div>
                            <div className='flex items-center my-2 '>
                                Frontend development for web: $80,000
                            </div>
                            <div className='flex items-center my-2 '>
                                Frontend development for app: $70,000
                            </div>
                            <div className='flex items-center my-2 '>
                                SEO + Blog development: $20,000
                            </div>
                            <div className='flex items-center my-2 '>
                                Integration of functionalities
                            </div>
                            <div className='w-1/4 h-1 bg-teal-400 my-6 print:hidden' />
                            <div className='flex items-center print:mt-2 mb-2 font-bold'>
                                <img src={SRC_TIME} className='size-5 mr-2' alt='' />
                                4 Months
                            </div>
                            <div className='flex items-center my-2 font-bold'>
                                <img src={SRC_MONEY} className='size-5 mr-2' alt='' />
                                $260,000.00 USD
                            </div>
                        </div>
                    </motion.div>

                    <motion.div
                        initial={{ y: 200 }}
                        whileInView={{ y: 0 }}
                        transition={{ damping: 1, string: 2 }}
                        viewport={{ once: true }} className='md:flex w-full p-6 md:p-12 print:p-4 bg-white rounded-3xl my-6 md:my-12  print:my-4'>
                        <div className='md:w-1/2 relative mb-4'>
                            <div className='size-3 outline outline-1 outline-offset-2 outline-teal-400 rounded-full absolute left-0 top-2 bg-teal-400 md:hidden hidden' />
                            <div className='text-base text-teal-400'>Phase three</div>
                            <div className='text-4xl font-bold'>Testing </div>
                        </div>

                        <div className='md:w-1/2 '>
                            <div className='flex items-center my-2'>
                                Unit testing: $10,000
                            </div>
                            <div className='flex items-center my-2 '>
                                Integration testing: $5,000
                            </div>
                            <div className='flex items-center my-2 '>
                                User acceptance testing: $5,000
                            </div>
                            <div className='flex items-center my-2 '>
                                Integration of functionalities
                            </div>
                            <div className='w-1/4 h-1 bg-teal-400 my-6 print:hidden' />
                            <div className='flex items-center print:mt-2 mb-2 font-bold'>
                                <img src={SRC_TIME} className='size-5 mr-2' alt='' />
                                1 Month
                            </div>
                            <div className='flex items-center my-2 font-bold'>
                                <img src={SRC_MONEY} className='size-5 mr-2' alt='' />
                                $20,000.00 USD
                            </div>

                        </div>
                    </motion.div>

                    <motion.div
                        initial={{ y: 200 }}
                        whileInView={{ y: 0 }}
                        transition={{ damping: 1, string: 2 }}
                        viewport={{ once: true }} className='md:flex w-full p-6 md:p-12 print:p-4 bg-white rounded-3xl my-6 md:my-12 print:my-4'>
                        <div className='md:w-1/2 relative mb-4'>
                            <div className='size-3 outline outline-1 outline-offset-2 outline-teal-400 rounded-full absolute left-0 top-2 bg-teal-400 md:hidden hidden' />
                            <div className='text-base text-teal-400'>Phase four</div>
                            <div className='text-4xl font-bold'>Deployment </div>
                        </div>

                        <div className='md:w-1/2 '>
                            <div className='flex items-center my-2'>
                                Deployment on production servers
                            </div>
                            <div className='flex items-center my-2 '>
                                User training sessions
                            </div>
                            <div className='w-1/4 h-1 bg-teal-400 my-6 print:hidden' />
                            <div className='flex items-center print:mt-2 mb-2 font-bold'>
                                <img src={SRC_TIME} className='size-5 mr-2' alt='' />
                                1 Month
                            </div>
                            <div className='flex items-center my-2 font-bold'>
                                <img src={SRC_MONEY} className='size-5 mr-2' alt='' />
                                $15,000.00 USD
                            </div>

                        </div>
                    </motion.div>

                    <motion.div
                        initial={{ y: 200 }}
                        whileInView={{ y: 0 }}
                        transition={{ damping: 1, string: 2 }}
                        viewport={{ once: true }} className='md:flex w-full p-6 md:p-12 print:p-4 bg-white rounded-3xl my-6 md:my-12 print:my-4'>
                        <div className='md:w-1/2 relative mb-4'>
                            <div className='size-3 outline outline-1 outline-offset-2 outline-teal-400 rounded-full absolute left-0 top-2 bg-teal-400 md:hidden hidden' />
                            <div className='text-base text-teal-400'>Phase five</div>
                            <div className='text-4xl font-bold'>Maintenance</div>
                        </div>

                        <div className='md:w-1/2 '>
                            <div className='flex items-center my-2'>
                                Continuous support and updates
                            </div>
                            <div className='flex items-center my-2 '>
                                Regular maintenance
                            </div>
                            <div className='flex items-center my-2 '>
                                Bug fixing
                            </div>
                            <div className='w-1/4 h-1 bg-teal-400 my-6 print:hidden' />
                            <div className='flex items-center print:mt-2 mb-2 font-bold'>
                                <img src={SRC_TIME} className='size-5 mr-2' alt='' />
                                on going monthly
                            </div>
                            <div className='flex items-center my-2 font-bold'>
                                <img src={SRC_MONEY} className='size-5 mr-2' alt='' />
                                $5,000.00 USD
                            </div>

                        </div>
                    </motion.div>




                    <motion.div
                        initial={{ y: 200 }}
                        whileInView={{ y: 0 }}
                        transition={{ damping: 1, string: 2 }}
                        viewport={{ once: true }} className='md:flex w-full py-12 print:p-4 border-t-4 border-teal-400 items-center my-12 !mb-0 print:my-4'>
                        <div className='md:w-1/2 relative'>
                            <div className='size-3 outline outline-1 outline-offset-2 outline-teal-400 rounded-full absolute left-0 top-2 bg-teal-400 md:hidden hidden' />
                            <div className='text-base text-teal-400 font-bold'>TOTAL BUDGET</div>
                        </div>

                        <div className='md:w-1/2 '>
                            <div className='text-4xl font-bold'>$320,000.00 USD </div>
                        </div>
                    </motion.div>

                    <motion.div
                        initial={{ y: 200 }}
                        whileInView={{ y: 0 }}
                        transition={{ damping: 1, string: 2 }}
                        viewport={{ once: true }} className='md:flex w-full py-12 print:p-4 border-t border-stone-300 items-center mb-12 print:my-4'>
                        <div className='md:w-1/2 relative'>
                            <div className='size-3 outline outline-1 outline-offset-2 outline-teal-400 rounded-full absolute left-0 top-2 bg-teal-400 md:hidden hidden' />
                            <div className='text-base text-black font-bold'>Payment terms</div>

                        </div>

                        <div className='md:w-1/2 '>
                            <div className='w-full my-4'>
                                <div className='font-bold'>Initial payment:</div> 20% ($64,000) upon project commencement
                            </div>
                            <div className='w-full my-4'>
                                <div className='font-bold'>Milestone payments:</div> 50% ($160,000) in phases aligned with project milestones
                            </div>
                            <div className='w-full my-4'>
                                <div className='font-bold'>Final payment:</div> 30% ($96,000) upon project completion
                            </div>

                        </div>
                    </motion.div>


                </div>
            </div>

            {/* <div className='w-full min-h-screen flex items-start justify-between bg-gradient-to-tr from-teal-400 to-teal-600'>
                <div className='max-w-screen-lg mx-auto py-32 p-6'>
                    <div className='w-full flex md:flex-nowrap flex-wrap'>
                        <Card />
                    </div>
                    <ProposalDevelopmentPlan />
                </div>
            </div> */}


        </>
    );
}


const ProposalDevelopmentPlan = () => {
    return (
        <div className="max-w-screen-lg mx-auto py-12 px-4">
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}
                className="w-full bg-white rounded-lg shadow-lg p-8"
            >
                <h2 className="text-3xl font-bold text-teal-400 mb-6">Development Plan</h2>

                {/* Phase 1 */}
                <PhaseCard
                    phaseNumber={1}
                    title="Initial Setup and Core Development"
                    duration="3 months"
                    totalCost="$160,000"
                    items={[
                        { name: "Administrator App", cost: "$40,000" },
                        { name: "Owner App (Web and Mobile)", cost: "$60,000" },
                        { name: "Horse Management App (Web and Mobile)", cost: "$60,000" },
                    ]}
                />

                {/* Phase 2 */}
                <PhaseCard
                    phaseNumber={2}
                    title="Extended Management Features"
                    duration="3 months"
                    totalCost="$150,000"
                    items={[
                        { name: "Equestrian Center Management App", cost: "$50,000" },
                        { name: "Veterinarian App (Web and Mobile)", cost: "$50,000" },
                        { name: "Stable Hand (Caballerango) App (Web and Mobile)", cost: "$50,000" },
                    ]}
                />

                {/* Phase 3 */}
                <PhaseCard
                    phaseNumber={3}
                    title="Specialized Roles and Competition Management"
                    duration="2 months"
                    totalCost="$100,000"
                    items={[
                        { name: "Trainer App (Mobile only)", cost: "$30,000" },
                        { name: "Farrier App (Mobile only)", cost: "$30,000" },
                        { name: "Competition Management App (Mobile only)", cost: "$40,000" },
                    ]}
                />

                {/* Phase 4 */}
                <PhaseCard
                    phaseNumber={4}
                    title="Finalization and Equipment Management"
                    duration="2 months"
                    totalCost="$50,000"
                    items={[{ name: "Club Equipment Management App (Web and Mobile)", cost: "$50,000" }]}
                />

                {/* Phase 5 */}
                <PhaseCard
                    phaseNumber={5}
                    title="Testing, QA, and Deployment"
                    duration="2 months"
                    totalCost="$90,000"
                    items={[
                        { name: "Comprehensive Testing and QA", cost: "$60,000" },
                        { name: "Deployment and Initial Support", cost: "$30,000" },
                    ]}
                />

                {/* Total Estimated Cost */}
                <div className="mt-8">
                    <div className="text-xl font-bold text-teal-400 mb-2">Total Estimated Cost</div>
                    <div className="text-2xl font-bold">$550,000</div>
                </div>

                {/* Payment Milestones */}
                <div className="mt-8">
                    <div className="text-xl font-bold text-teal-400 mb-2">Payment Milestones</div>
                    <div className="grid grid-cols-2 gap-4">
                        <PaymentMilestone name="Phase 1 Completion" amount="$160,000" />
                        <PaymentMilestone name="Phase 2 Completion" amount="$150,000" />
                        <PaymentMilestone name="Phase 3 Completion" amount="$100,000" />
                        <PaymentMilestone name="Phase 4 Completion" amount="$50,000" />
                        <PaymentMilestone name="Phase 5 Completion" amount="$90,000" />
                    </div>
                </div>
            </motion.div>
        </div>
    );
};

const PhaseCard = ({ phaseNumber, title, duration, totalCost, items }) => {
    return (
        <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.2 }}
            className="mb-8"
        >
            <div className="text-2xl font-bold mb-2">Phase {phaseNumber}</div>
            <div className="text-xl font-bold text-teal-400 mb-2">{title}</div>
            <div className="flex justify-between mb-4">
                <div className="text-gray-600">Duration: {duration}</div>
                <div className="text-gray-600">Total Cost: {totalCost}</div>
            </div>
            <ul className="list-disc pl-6">
                {items.map((item, index) => (
                    <li key={index} className="text-gray-700">
                        {item.name}: {item.cost}
                    </li>
                ))}
            </ul>
        </motion.div>
    );
};

const PaymentMilestone = ({ name, amount }) => {
    return (
        <div className="bg-gray-100 rounded-lg p-4">
            <div className="text-lg font-bold">{name}</div>
            <div className="text-gray-600">{amount}</div>
        </div>
    );
};


export function Card(props) {

    return (
        <>
            <div className='w-full rounded-2xl bg-white'>
                <div className='w-full flex items-center'>
                    <div className='w-[200px] text-4xl font-bold p-6 flex items-center justify-center'>
                        $155k
                    </div>
                </div>
            </div>

        </>
    );
}

function AppList() {
    const apps = [
        {
            name: "Administrator App",
            category: "Web Only",
            features: [
                "Configuration of Parque Hípicos",
                "User structure configuration with Excel upload",
                "Configuration for Owners, Sponsors, Horses, Veterinarians, Farriers, Public Users, Riders",
                "Role and access configuration",
                "Access to modules and change logs"
            ]
        },
        {
            name: "Owner App",
            category: "Web and Mobile",
            features: [
                "General information about the owner",
                "Horse dossier",
                "Personal information",
                "Feeding records",
                "Shoeing records",
                "Medical check-ups",
                "Bit type, etc.",
                "PDF documents (passport, pedigree, etc.)"
            ]
        },
        {
            name: "Horse Management App",
            category: "Web and Mobile",
            features: [
                "Horse dossier",
                "Personal information",
                "Feeding records",
                "Shoeing records",
                "Medical check-ups",
                "Bit type, etc.",
                "PDF documents (passport, pedigree, etc.)"
            ]
        },
        {
            name: "Equestrian Center Management App",
            category: "Web Only",
            features: [
                "School class schedules",
                "Horse feeding records",
                "Health conditions, medications, treatments",
                "Competitions",
                "Competition logistics (horse transportation)",
                "Event participation fees and stable assignments",
                "Stable locations and horse placement",
                "Pension management",
                "Administrative procedures",
                "Inventory management (feed, consumables)"
            ]
        },
        {
            name: "Veterinarian App",
            category: "Web and Mobile",
            features: [
                "Deworming records",
                "Disease management",
                "Record updates",
                "Follow-up notes"
            ]
        },
        {
            name: "Trainer App",
            category: "Mobile Only",
            features: [
                "Riding schedules and logs",
                "Notes per horse",
                "Notes per rider/amazona"
            ]
        },
        {
            name: "Farrier App",
            category: "Mobile Only",
            features: [
                "Shoeing schedules and logs"
            ]
        },
        {
            name: "Competition Management App",
            category: "Mobile Only",
            features: [
                "Competition dates and results",
                "Detailed breakdown of competitions and variables (moñas, categories, positions)",
                "Existing categories"
            ]
        },
        {
            name: "Stable Hand (Caballerango) App",
            category: "Web and Mobile",
            features: [
                "Tip management by rider",
                "Horse notes with notifications to rider, owner, and equestrian center"
            ]
        },
        {
            name: "Club Equipment Management App",
            category: "Web and Mobile",
            features: [
                "Equipment control for riders (saddles, whips, bits, etc.)"
            ]
        }
    ];

    return (
        <div className='w-full min-h-screen flex flex-col items-start justify-start bg-gray-50 py-32 pb-10 px-6'>
            <div className='max-w-screen-lg w-full mx-auto'>
                <div className='text-4xl md:text-6xl font-bold text-teal-400 mb-12'>List of Apps</div>
                {apps.map((app, index) => (
                    <div key={index} className='mb-10'>
                        <div className='text-2xl font-bold'>{app.name}</div>
                        <div className='text-xl text-teal-400'>{app.category}</div>
                        <ul className='list-disc list-inside mt-2'>
                            {app.features.map((feature, i) => (
                                <li key={i} className='text-base'>{feature}</li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        </div>
    );
}


// function AppList() {
//     const apps = [
//         {
//             name: "Admin Dashboard",
//             category: "Admin",
//             description: "The Admin Dashboard allows administrators to manage users, settings, and overall platform performance. Key features include user management, analytics, and content moderation."
//         },
//         {
//             name: "Instructor Portal",
//             category: "Instructor",
//             description: "The Instructor Portal provides instructors with tools to manage their courses, track student progress, and interact with students. Features include course creation, assignment tracking, and grading."
//         },
//         {
//             name: "Student App",
//             category: "Student",
//             description: "The Student App offers students access to their courses, assignments, and grades. It includes features like course enrollment, progress tracking, and interaction with instructors and peers."
//         },
//         {
//             name: "Parent Monitoring App",
//             category: "Parent",
//             description: "The Parent Monitoring App allows parents to monitor their children's progress and activities. Features include viewing grades, tracking attendance, and receiving notifications about important events."
//         },
//         {
//             name: "Content Management System",
//             category: "Content Manager",
//             description: "The Content Management System (CMS) enables content managers to create, update, and organize learning materials. Features include content creation, categorization, and scheduling."
//         },
//         {
//             name: "Finance Management App",
//             category: "Finance",
//             description: "The Finance Management App helps the finance team track and manage financial transactions, budgets, and reports. Key features include payment processing, expense tracking, and financial reporting."
//         }
//     ];

//     return (
//         <div className='w-full min-h-screen flex flex-col items-start justify-start bg-gray-50 py-32 px-6'>
//             <div className='max-w-screen-lg w-full mx-auto'>
//                 <div className='text-4xl md:text-6xl font-bold text-teal-400 mb-12'>List of Apps</div>
//                 {apps.map((app, index) => (
//                     <div key={index} className='mb-10'>
//                         <div className='text-2xl font-bold'>{app.name}</div>
//                         <div className='text-xl text-teal-400'>{app.category}</div>
//                         <div className='text-base mt-2'>{app.description}</div>
//                     </div>
//                 ))}
//             </div>
//         </div>
//     );
// }

const Navbar = () => {
    function handlePrint() {
        window.print()
    }
    const [showNavbar, setShowNavbar] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);

    const handleScroll = () => {
        if (window.scrollY > lastScrollY) {
            // if scroll down
            setShowNavbar(false);
        } else {
            // if scroll up
            setShowNavbar(true);
        }
        setLastScrollY(window.scrollY);
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [lastScrollY]);

    return (
        <>


            <div className={`fixed text-white print:justify-end print:text-black top-0 print:bottom-0 print:top-auto z-50 bg-stone-900/90 backdrop-blur-sm print:bg-transparent left-0 w-full p-4 md:px-10 md:py-4 print:p-0 flex transition-all duration-500 items-center ${showNavbar ? "translate-y-0" : "-translate-y-full"}`}>
                <div className="text-3xl font-bold font-aeonik">all<span className="text-teal-400"></span><span className="text-teal-400">zem</span>.</div>
                {/*<img className='mix-blend-screen invert size-24 contrast-150 object-contain' src={SRC_LOGO} />*/}
                <div className='h-12 w-px bg-stone-700 mx-6 print:hidden md:block hidden' />
                <div className='text-xs uppercase print:hidden md:block hidden'>Ecuestrian proposal</div>
                <button className='size-[40px] transition-all hover:outline outline outline-0 hover:outline-4 outline-offset-1 outline-teal-400 group justify-center ml-auto relative bg-white rounded-full text-stone-900 print:hidden text-sm flex items-center' onClick={handlePrint}>
                    <img src={SRC_PRINT} className='size-5' alt='' />
                    <div className='size-px absolute -bottom-1 left-1/2 flex items-start justify-center transition-all  translate-y-2 invisible group-hover:visible group-hover:translate-y-0 '>
                        <div className='flex-shrink-0 px-2 py-1 text-xs bg-black text-white rounded-lg'>Print</div>
                    </div>
                </button>
            </div>
        </>
    );
};