import { motion, useScroll, Variants } from "framer-motion";
import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';

import SRC_CLOSE from "../assets/img/close.svg";

import { CaseSide } from "./CaseSide";

export function ButtonBack() {
    function handlePrint() {
        window.print()
    }
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };



    return (
        <>

            <CaseSide isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
            <div className="w-full flex items-center justify-start fixed top-0 left-0 z-[999]">
                <Link to="/" className="size-11 md:size-16 relative bg-white cursor-pointer group flex items-center justify-center shadow-md">
                    <img className="relative group-hover:invert-0 invert z-20" src={SRC_CLOSE} />
                    <div className="size-full absolute origin-left bg-neutral-800 left-0 top-0 scale-x-0 transition-all duration-100 group-hover:scale-x-100" />
                </Link>
                <button onClick={toggleSidebar} className="h-11 md:h-16 px-4 md:px-6 hover:bg-neutral-800 text-sm text-white bg-neutral-950">All <span className="md:inline-block hidden">case studies</span></button>
            </div>


        </>
    );
};