import { Link } from 'react-router-dom';
import SRC_ARROW from "../assets/img/arrow.svg";
import { ButtonBack } from '../components/ButtonBack';
import SRC_CLICKAI from "../assets/img/case-studies/clickai.jpg";
import SRC_CLICKAIWO from "../assets/img/case-studies/clickai-2.jpg";
import SRC_CLICKAITHREE from "../assets/img/case-studies/clickai-3.jpg";
import SRC_CLICKAIFOUR from "../assets/img/case-studies/clickai-4.jpg";

import { motion } from 'framer-motion';
import ImageSlider from '../components/ImageSlider';

export function Clickai() {
    const images = [
        SRC_CLICKAI,
        SRC_CLICKAIWO,
        SRC_CLICKAITHREE,
        SRC_CLICKAIFOUR
        
      ];
    return (

        <motion.div initial={{opacity:0, scale:0.8}} transition={{duration:0.3}} animate={{opacity:1, scale:1}} exit={{opacity:0, scale:0.8}} className="w-full h-full md:flex justify-between items-center bg-gray-50 text-neutral-800 md:overflow-hidden overflow-auto fixed z-[99999]">
            <ButtonBack />

                <div className="w-full overflow-hidden h-1/2 md:h-full md:w-1/2 order-2 bg-neutral-950">
                    {/*<motion.img initial={{scale:1}} animate={{scale:1.05}} transition={{duration:4}} src={SRC_BABONBO} className='w-full h-full object-cover' />*/}
                    <ImageSlider images={images} />
                </div>
                <div className="w-full md:w-1/2 md:h-full order-1 overflow-auto md:p-28 md:pt-36 p-6 md:my-auto">
                    <div>
                        <div className="text-sm text-teal-400 uppercase">Case study</div>
                        <h1 className="text-3xl md:text-6xl font-bold">ClickAi</h1>
                        <div className="text-xl mt-4">All-in-one automated A.I. platform where users build and manage their website and promotions</div>
                        <div className="flex gap-4 justify-between text-base py-7 border-b border-t border-neutral-200 my-10">
                            <div className="">
                                <div className="font-bold">Year</div>
                                <div>2023</div>
                            </div>

                            <div className="">
                                <div className="font-bold">Solution</div>
                                <div>Web App</div>
                            </div>
                            
                            <div className="">
                                <div className="font-bold">Website</div>
                               <Link className='text-teal-400' to="https://www.clicknet.me/" target='_blank'>clickai.com</Link>
                            </div>
                        </div>
                        <div>
                        ClickAi is revolutionizing the way users build and edit websites by harnessing the power of artificial intelligence. With ClickAi, users can create fully functional websites in seconds using simple voice or written commands. This innovative approach eliminates the need for extensive coding knowledge, making web development accessible to everyone, regardless of their technical expertise.
 
                        <br /><br />
                        The app also features an intuitive editor that allows users to make quick adjustments and refinements to their websites. This editor is designed to be user-friendly, enabling even those with minimal design experience to craft visually appealing and highly functional sites. Whether it's dragging and dropping elements, tweaking layouts, or adding custom features, the editor provides a seamless experience that enhances the overall web-building process.
                        <br /><br />
                        In addition to its AI-driven capabilities and intuitive editor, ClickAi offers robust customization options to meet diverse needs. Users can easily integrate various functionalities, such as e-commerce platforms, contact forms, and social media links, to create a comprehensive online presence. ClickAi's blend of advanced technology and user-centric design empowers individuals and businesses to quickly and efficiently bring their web visions to life.

                        </div>
                    </div>
                </div>

        </motion.div>
    );
} 