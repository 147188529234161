import { motion, useScroll, Variants } from "framer-motion";
import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';

import { CaseSide } from "./CaseSide";

export function Navbar() {
    function handlePrint() {
        window.print()
    }
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

    const [showNavbar, setShowNavbar] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);

    const handleScroll = () => {
        if (window.scrollY > lastScrollY) {
            // if scroll down
            setShowNavbar(false);
        } else {
            // if scroll up
            setShowNavbar(true);
        }
        setLastScrollY(window.scrollY);
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [lastScrollY]);

    return (
        <>

            <CaseSide isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
            <div className={`fixed select-none text-white print:justify-end print:text-black top-0 print:bottom-0 print:top-auto z-[887] print:bg-transparent left-0 w-full p-4 md:px-10 md:py-6 print:p-0 flex justify-between transition-all duration-500 items-center ${showNavbar ? "translate-y-0 bg-neutral-950 md:bg-transparent" : "-translate-y-full"}`}>
                <Link to="/" className="text-3xl font-bold font-aeonik">all<span className="text-teal-400"></span><span className="text-teal-400">zem</span>.</Link>
                {/*
                <div className='h-12 w-px bg-stone-700 mx-6 print:hidden md:block hidden' />*/}
                <div className="flex gap-x-8">
                    {/*<Link to="#about" className='text-xs uppercase print:hidden md:block hidden font-semibold'>About us</Link>*/}
                    <div onClick={toggleSidebar} className='text-xs hover:text-teal-400 uppercase print:hidden block font-semibold cursor-pointer'>Case studies</div>
                    <Link to="mailto:clickaiapp@gmail.com" className='text-xs hover:text-teal-400 uppercase print:hidden block font-semibold'>Contact us</Link>
                </div>

            </div>
        </>
    );
};