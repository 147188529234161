import { Link } from 'react-router-dom';
import SRC_ARROW from "../assets/img/arrow.svg";
import SRC_CLOSE from "../assets/img/close.svg";
import SRC_AVIOYX from "../assets/img/case-studies/avioyx.jpg";
import SRC_AVIOYXWO from "../assets/img/case-studies/avioyx-2.jpg";
import SRC_AVIOYXTHREE from "../assets/img/case-studies/avioyx-3.jpg";
import SRC_AVIOYXFOUR from "../assets/img/case-studies/avioyx-4.jpg";
import SRC_AVIOYXFIVE from "../assets/img/case-studies/avioyx-5.jpg";
import { motion } from 'framer-motion';
import ImageSlider from '../components/ImageSlider';
import { ButtonBack } from '../components/ButtonBack';

export function Avioyx() {
    const images = [
        SRC_AVIOYX,
        SRC_AVIOYXWO,
        SRC_AVIOYXTHREE,
        SRC_AVIOYXFOUR,
        SRC_AVIOYXFIVE
        
      ];
    return (

        <motion.div initial={{opacity:0, scale:0.8}} transition={{duration:0.3}} animate={{opacity:1, scale:1}} exit={{opacity:0, scale:0.8}} className="w-full h-full md:flex justify-between items-center md:overflow-hidden overflow-auto bg-gray-50 text-neutral-800 fixed z-[99999]">
            <ButtonBack />
           
                <div className="w-full overflow-hidden h-1/2 md:h-full md:w-1/2 order-2 bg-neutral-950">
                    {/*<motion.img initial={{scale:1}} animate={{scale:1.05}} transition={{duration:4}} src={SRC_BABONBO} className='w-full h-full object-cover' />*/}
                    <ImageSlider images={images} />
                </div>
                <div className="w-full md:w-1/2 md:h-full order-1 overflow-auto md:p-28 md:pt-36 p-6 md:my-auto">
                    <div>
                        <div className="text-sm text-teal-400 uppercase">Case study</div>
                        <h1 className="text-3xl md:text-6xl font-bold">Avioyx</h1>
                        <div className="text-xl mt-4">Avioyx is an innovative platform designed to cater to pilots at all stages of their careers, from aspiring aviators to seasoned professionals holding various licenses.</div>
                        <div className="flex gap-4 justify-between text-base py-7 border-b border-t border-neutral-200 my-10">
                            <div className="">
                                <div className="font-bold">Year</div>
                                <div>2024</div>
                            </div>

                            <div className="">
                                <div className="font-bold">Solution</div>
                                <div>Web & iPad App</div>
                            </div>
                            
                            <div className="">
                                <div className="font-bold">Website</div>
                                <div>Coming soon</div>
                            </div>
                        </div>
                        <div>
                        The platform provides a comprehensive suite of tools to assist pilots in air navigation and flight planning, significantly enhancing the capabilities of their electronic flight bags (EFBs). With Avioyx, pilots can streamline their flight operations, ensuring safer and more efficient flights through real-time data, advanced route planning, and seamless integration with modern aviation technologies.
 
                        <br /><br />
                        Beyond individual pilot support, Avioyx offers a robust marketplace for aircraft rentals and instructor hiring, facilitating easy access to resources necessary for both training and recreational flying. This marketplace is a boon for pilots seeking to expand their flying hours or learn new skills without the hassle of long-term commitments. Additionally, Avioyx supports flight academies by providing a comprehensive management system that handles courses, flights, missions, and scheduling. This all-encompassing approach ensures that flight schools can operate smoothly, efficiently, and with full oversight of their programs and students.
                        <br /><br />
                        As the ultimate tool for pilots, Avioyx stands out with its user-centric design and wide range of functionalities. Whether a user is just starting their journey in aviation or looking to refine their skills and knowledge, the platform’s features are tailored to meet their needs. By integrating crucial flight planning tools, a marketplace for essential services, and comprehensive management capabilities for flight schools, Avioyx positions itself as the premier choice for pilots and aviation academies alike.

                        </div>
                    </div>
                </div>

        </motion.div>
    );
} 