import React, { useState, useEffect } from "react";
import { Navbar } from "../components/Navbar";
import { BackgroundBeams } from "../components/Beams";
import { FlipWords } from "../components/Words";
import { Link } from 'react-router-dom';
import { AuroraBackground } from "../components/Aurora";

import { CaseSide } from "../components/CaseSide";


import SRC_CLICK from "../assets/img/click-1.jpg";
import SRC_TOMAS from "../assets/img/tomas.png";
import SRC_WORK from "../assets/img/case-studies/work.jpg";
import SRC_AHMED from "../assets/img/ahmed.png";
import SRC_AVIOYX from "../assets/img/avioyx.png";
import SRC_MAYA from "../assets/img/maya.jpg";
import SRC_IPHONE from "../assets/img/iphone.png";
import SRC_DOGU from "../assets/img/dogu.jpg";
import SRC_IPAD from "../assets/img/ipad.png";
import SRC_SURFYX from "../assets/img/surfyx.jpg";
import SRC_BABONBO from "../assets/img/babonbo.jpg";

import { motion, useScroll, Variants, useTransform } from "framer-motion";


export function Home() {
    const cardVariants = {
        offscreen: {
            y: 300
        },
        onscreen: {
            y: 50,
            rotate: -10,
            transition: {
                type: "spring",
                bounce: 0.4,
                duration: 0.8
            }
        }
    };



    const { scrollYProgress } = useScroll();
    return (
        <>
            <motion.div className='h-1 w-full origin-left bg-teal-400 fixed top-0 left-0 z-50' style={{ scaleX: scrollYProgress }} />
            <Navbar />

            <Hero />
            <Screens />
            {/*<Work />*/}
            <About />
            {/* <Team /> */}
            <Footer />

        </>
    );
}



const Hero = () => {
    const words = ["Tech Startups", "Software Creators", "Digital Disruptors", "Tech Visionaries"];
    return (
        <>
            <div className="w-full h-full flex justify-end flex-col items-start  bg-neutral-950 text-white p-6 md:p-24">
                <BackgroundBeams />
                <div className="max-w-screen-sm relative z-40">
                    <h1 className="text-4xl md:text-6xl font-bold">Fueling the Next Generation of <br /><span className="text-teal-400"><FlipWords words={words} /></span></h1>
                    <p className="md:w-3/4 text-lg font-light mt-4">Empowering innovation with $200K worth of investments, expert mentorship, and unparalleled resources.</p>
                </div>
            </div>
        </>
    );
};

const About = () => {

    return (
        <>
            <div id="about" className="w-full flex items-center  bg-neutral-950 text-white py-24">



                <div className="w-full relative bg-neutral-950">

                    <div className="max-w-screen-xl pd:p-0 p-8 mx-auto md:flex">
                        <h1 className="text-4xl md:w-2/3 md:text-6xl font-bold">We are dedicated to nurturing <span className="text-teal-400">tech-driven startups.</span></h1>
                        <p className="md:w-1/3 text-lg font-light mt-4">Our mission is to support visionary entrepreneurs by providing them with the resources, mentorship, and capital they need to turn groundbreaking ideas into successful businesses. At AllZemHub, we believe in the transformative power of technology to change the world.</p>
                    </div>

                    {/*<AuroraBackground />*/}
                </div>
            </div>
        </>
    );
};

const Work = () => {
    const cardVariants = {
        offscreen: {
            y: 300
        },
        onscreen: {
            y: 50,
            rotate: -10,
            transition: {
                type: "spring",
                bounce: 0.4,
                duration: 0.8
            }
        }
    };




    return (
        <div className="w-full min-h-full bg-neutral-950">
            {/*<div className="flex items-center justify-between w-full text-white px-24 pt-24">
                <div className="text-3xl font-bold">Case studies</div>
                <Link className="px-8 py-4 rounded-full bg-gradient-to-tr from-teal-500 to-teal-300 text-white">View all</Link>
            </div>*/}
            <motion.div
                initial={{ y: 200 }}
                whileInView={{ y: 0 }}
                transition={{ damping: 1, string: 2 }}
                viewport={{ once: true }}
                className="w-full min-h-full overflow-auto flex gap-6 bg-neutral-950 items-start text-white p-6 md:p-24 md:pb-32 md:pt-24">
                <div className="w-[300px] group relative p-5 flex-shrink-0">
                    <img className="absolute left-0 top-0 z-30" src={SRC_IPHONE} />
                    <img className="rounded-3xl z-20 relative -translate-y-4 " src={SRC_MAYA} />
                    <div className="absolute rounded-3xl opacity-30 group-hover:opacity-100 transition-all duration-200 scale-90 top-0 left-0 w-full h-full bg-gradient-to-tr blur-xl from-teal-400 to-pink-500" />
                </div>

                <div className="w-[300px] group relative p-5 flex-shrink-0">
                    <img className="absolute left-0 top-0 z-30" src={SRC_IPHONE} />
                    <img className="rounded-3xl z-20 relative -translate-y-4" src={SRC_SURFYX} />
                    <div className="absolute rounded-3xl opacity-30 group-hover:opacity-100 transition-all duration-200 scale-90 top-0 left-0 w-full h-full bg-gradient-to-tr blur-xl from-teal-400 to-pink-500" />
                </div>
                <div className="w-[300px] group relative p-5 flex-shrink-0">
                    <img className="absolute left-0 top-0 z-30" src={SRC_IPHONE} />
                    <img className="rounded-3xl z-20 relative -translate-y-4" src={SRC_DOGU} />
                    <div className="absolute rounded-3xl opacity-30 group-hover:opacity-100 transition-all duration-200 scale-90 top-0 left-0 w-full h-full bg-gradient-to-tr blur-xl from-teal-400 to-pink-500" />
                </div>

                <div className="h-[570px] group relative p-0 flex-shrink-0">

                    <img className="z-20 h-full relative  border-8 border-black rounded-3xl" src={SRC_CLICK} />
                    <div className="absolute rounded-3xl opacity-30 group-hover:opacity-100 transition-all duration-200 scale-100 top-0 left-0 w-full h-full bg-gradient-to-tr blur-xl from-teal-400 to-pink-500" />
                </div>

                <div className="h-[570px] group relative flex-shrink-0">

                    <img className="rounded-3xl h-full z-20 relative border-8 border-black" src={SRC_AVIOYX} />
                    <div className="absolute rounded-3xl opacity-30 group-hover:opacity-100 transition-all duration-200 scale-100 top-0 left-0 w-full h-full bg-gradient-to-tr blur-xl from-teal-400 to-pink-500" />
                </div>
                <div className="h-[570px] group relative p-0 flex-shrink-0">

                    <img className="z-20 relative h-full border-8 border-black rounded-3xl" src={SRC_BABONBO} />
                    <div className="absolute rounded-3xl opacity-30 group-hover:opacity-100 transition-all duration-200 scale-100 top-0 left-0 w-full h-full bg-gradient-to-tr blur-xl from-teal-400 to-pink-500" />
                </div>



            </motion.div>
        </div>
    );
};

const Team = () => {

    return (
        <>
            <div className="w-full pb-24 flex  items-center justify-center  bg-neutral-950 text-white">
                <div className="max-w-screen-lg flex-wrap md:flex-row flex-col flex md:p-0 p-12">
                    <div className="w-full p-8 md:w-1/3 flex flex-col items-start  rounded-3xl overflow-hidden">
                        <img className="bg-neutral-950" src={SRC_AHMED} alt="" />
                        <div className="p-6 bg-neutral-900 rounded-3xl w-full">
                            <h1 className="text-xl font-bold">Ahmed Allem</h1>
                            <div className="text-sm text-emerald-400">Founder, Lead Developer</div>
                            <p className="text-sm hidden">Digital Designer from Bratislava, Slovakia, has been at the forefront of design and development for the past 15 years. Continuously hungry for new challenges and experiences, Timmy stays abreast of the latest technology trends. His unique combination of front-end development skills and a designer's spirit allows him to create innovative and impactful solutions, always striving to achieve the best possible outcomes.</p>
                        </div>
                    </div>
                    <div className="w-full p-8 md:w-1/3 flex flex-col items-start  rounded-3xl overflow-hidden">
                        <img className="bg-neutral-950" src={SRC_TOMAS} alt="" />
                        <div className="p-6 bg-neutral-900 rounded-3xl w-full">
                            <h1 className="text-xl font-bold">Tomas Zeman</h1>
                            <div className="text-sm text-emerald-400">Founder, UX/UI Designer</div>
                            <p className="text-sm hidden">Digital Designer from Bratislava, Slovakia, has been at the forefront of design and development for the past 15 years. Continuously hungry for new challenges and experiences, Timmy stays abreast of the latest technology trends. His unique combination of front-end development skills and a designer's spirit allows him to create innovative and impactful solutions, always striving to achieve the best possible outcomes.</p>
                        </div>
                    </div>
                    <div className="w-full p-8 md:w-1/3 flex flex-col items-start  rounded-3xl overflow-hidden">
                        <img className="bg-neutral-950" src={SRC_TOMAS} alt="" />
                        <div className="p-6 bg-neutral-900 rounded-3xl w-full">
                            <h1 className="text-xl font-bold">Tomas Zeman</h1>
                            <div className="text-sm text-emerald-400">Founder, UX/UI Designer</div>
                            <p className="text-sm hidden">Digital Designer from Bratislava, Slovakia, has been at the forefront of design and development for the past 15 years. Continuously hungry for new challenges and experiences, Timmy stays abreast of the latest technology trends. His unique combination of front-end development skills and a designer's spirit allows him to create innovative and impactful solutions, always striving to achieve the best possible outcomes.</p>
                        </div>
                    </div>
                    <div className="w-full p-8 md:w-1/3 flex flex-col items-start  rounded-3xl overflow-hidden">
                        <img className="bg-neutral-950" src={SRC_TOMAS} alt="" />
                        <div className="p-6 bg-neutral-900 rounded-3xl w-full">
                            <h1 className="text-xl font-bold">Tomas Zeman</h1>
                            <div className="text-sm text-emerald-400">Founder, UX/UI Designer</div>
                            <p className="text-sm hidden">Digital Designer from Bratislava, Slovakia, has been at the forefront of design and development for the past 15 years. Continuously hungry for new challenges and experiences, Timmy stays abreast of the latest technology trends. His unique combination of front-end development skills and a designer's spirit allows him to create innovative and impactful solutions, always striving to achieve the best possible outcomes.</p>
                        </div>
                    </div>
                    <div className="w-full p-8 md:w-1/3 flex flex-col items-start  rounded-3xl overflow-hidden">
                        <img className="bg-neutral-950" src={SRC_TOMAS} alt="" />
                        <div className="p-6 bg-neutral-900 rounded-3xl w-full">
                            <h1 className="text-xl font-bold">Tomas Zeman</h1>
                            <div className="text-sm text-emerald-400">Founder, UX/UI Designer</div>
                            <p className="text-sm hidden">Digital Designer from Bratislava, Slovakia, has been at the forefront of design and development for the past 15 years. Continuously hungry for new challenges and experiences, Timmy stays abreast of the latest technology trends. His unique combination of front-end development skills and a designer's spirit allows him to create innovative and impactful solutions, always striving to achieve the best possible outcomes.</p>
                        </div>
                    </div>
                    <div className="w-full p-8 md:w-1/3 flex flex-col items-start  rounded-3xl overflow-hidden">
                        <img className="bg-neutral-950" src={SRC_TOMAS} alt="" />
                        <div className="p-6 bg-neutral-900 rounded-3xl w-full">
                            <h1 className="text-xl font-bold">Tomas Zeman</h1>
                            <div className="text-sm text-emerald-400">Founder, UX/UI Designer</div>
                            <p className="text-sm hidden">Digital Designer from Bratislava, Slovakia, has been at the forefront of design and development for the past 15 years. Continuously hungry for new challenges and experiences, Timmy stays abreast of the latest technology trends. His unique combination of front-end development skills and a designer's spirit allows him to create innovative and impactful solutions, always striving to achieve the best possible outcomes.</p>
                        </div>
                    </div>
                </div>



            </div>
        </>
    );
};

const Screens = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const { scrollY } = useScroll();
    const x = useTransform(scrollY, [0, 1000], [0, 150]); // Adjust the range and distance as needed
    const y = useTransform(scrollY, [0, 1000], [0, 100]); // Adjust the range and distance as needed
    return (
        <div className="w-full bg-neutral-950 relative">
             <CaseSide  isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
            <div className="h-1 w-full absolute bottom-0 left-0 z-30 bg-gradient-to-tr from-teal-200 to-teal-400" />
            <motion.div style={{ y }} className="w-full md:w-1/2 flex flex-col justify-start items-start mx-auto  md:absolute z-20 right-0 top-0 text-white md:p-24 md:py-0 p-6 my-20">
                <h1 className="text-4xl w-full md:text-4xl font-bold">Case studies</h1>
                <div onClick={toggleSidebar} className="px-6 cursor-pointer hover:to-teal-500 mt-4 py-3 block rounded-full bg-gradient-to-tr from-teal-500 to-teal-300 text-white">Discover</div>
            </motion.div>
            <motion.img style={{ x }}
                src={SRC_WORK} className="w-full -left-[150px] relative" />
        </div>
    );
};

const Footer = () => {

    return (
        <div className="w-full bg-neutral-900 relative">
            <div className="max-w-screen-xl mx-auto py-16 bg-neutral-900 text-white text-center">
                <div className="w-full text-sm">&copy; AllZem 2024</div>
            </div>
        </div>
    );
};